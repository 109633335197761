@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-262 {
    width: 262px;
  }

  .w-584 {
    width: 584px;
  }

  .w-1024 {
    width: 1024px;
  }

  .w-1152 {
    width: 1152px;
  }

  .w-1280 {
    width: 1480px;
  }

  .mh-295 {
    min-height: 295px;
  }

  .mh-330 {
    min-height: 330px;
  }

  .mh-351 {
    min-height: 351px;
  }

  .mh-250 {
    min-height: 250px;
  }

  .h-blood-pressure-set-view-body {
    height: calc(100% - 250px);
  }

  .h-measurement-view-body {
    height: calc(100% - 351px);
  }

  .mw-262 {
    min-width: 262px;
  }

  .max-h-192 {
    max-height: 48rem;
  }

  .flex-basis-200 {
    flex-basis: 200px;
  }
}

@font-face {
  font-family: 'EncodeSans';
  src: url('./fonts/EncodeSans-VariableFont_wdth,wght.ttf') format('truetype-variations');
  font-weight: 100 1000;
  font-display: swap;
}

html {
  height: 100%;
}

body {
  font-family: EncodeSans, 'sans-serif';
  background-color: #f4f2f7; /* rivaOffwhite */
  height: 100%;
}

@layer base {
  h1 {
    @apply text-5xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
    @apply font-bold;
    @apply mb-4;
  }
}

.Toastify__toast-container {
  border: 0;
  width: 420px;
}

.Toastify__toast--error {
  @apply bg-rivaWhite;
  @apply border-t-8;
  @apply border-rivaFuchsia;
  @apply border-solid;
}

.Toastify__toast--success {
  @apply bg-rivaWhite;
  @apply border-t-8;
  @apply border-rivaLime-500;
  @apply border-solid;
}

.Toastify__toast {
  padding: 0;
  @apply rounded-lg;
}

/*
 Note: This is to hide the ability to edit/delete messages
 */
.sendbird-message-item-menu {
  display: none !important;
}

.riva-input:focus ~ .riva-form-label:not(.riva-invalid):not(.riva-warning),
.riva-input:focus-within ~ .riva-form-label:not(.riva-invalid):not(.riva-warning) {
  @apply text-rivaPurple-500;
}

input:invalid,
.riva-input:invalid {
  @apply border-rivaFuchsia-500;
  @apply bg-rivaFuchsia-50;
  @apply text-rivaFuchsia-800;
}

.riva-input:invalid ~ .riva-form-label,
.riva-input:invalid:focus ~ .riva-form-label {
  @apply text-rivaFuchsia-500;
}

[data-popper-placement^='top'] > [data-popper-arrow='true'] {
  bottom: -4px;
}

[data-popper-placement^='bottom'] > [data-popper-arrow='true'] {
  top: -4px;
}

[data-popper-placement^='left'] > [data-popper-arrow='true'] {
  right: -4px;
}

[data-popper-placement^='right'] > [data-popper-arrow='true'] {
  left: -4px;
}

.nav-item-left-icon {
  @apply text-rivaPurple-300;
}

.nav-item-content,
.nav-item-right-icon {
  @apply text-rivaPurple-100;
}

a:hover > div > .nav-item-left-icon {
  @apply text-rivaPurple-100;
}

.active .nav-item-left-icon {
  @apply text-rivaPurple-100;
}

.active .nav-item-content,
.active .nav-item-right-icon {
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.react-datepicker {
  font-family: EncodeSans, 'sans-serif';
}

.react-datepicker__month-container {
  float: none;
}

.react-datepicker__week,
.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__week {
  height: 32px;
  margin-bottom: 12px;
}

.react-datepicker__month {
  @apply m-0;
}

.react-datepicker__year {
  margin: 0;
}

.react-datepicker__year-wrapper {
  @apply grid;
  @apply grid-cols-5;
  grid-auto-rows: 32px;
  @apply max-w-none;
  row-gap: 20px;
}

.react-datepicker__navigation--next {
  top: 28px;
  right: 28px;
}

.react-datepicker__current-month {
  margin: 0 0 16px 0;
  @apply text-sm;
  @apply font-semibold;
  padding: 4px 0;
}

.react-datepicker__header {
  @apply bg-transparent;
  border-bottom: none;
  padding: 0;
}

.react-datepicker__day-names {
  margin: 0 0 12px 0;
  @apply text-rivaOffblack-400;
  line-height: 32px;
}

.react-datepicker__day-name {
  width: 40px;
  height: 32px;
  margin: 0;
  @apply text-rivaOffblack-400;
  @apply text-sm;
}

.react-datepicker__day {
  width: 40px;
  height: 32px;
  margin: 0;
  @apply lining-nums;
  @apply text-sm;
  @apply leading-8;
  @apply text-rivaOffblack-900;
}

.react-datepicker__day:hover {
  @apply bg-rivaPurple-100;
}

.react-datepicker__day--today {
  @apply font-normal;
  @apply bg-rivaOffblack-200;
}

.react-datepicker__year-text--disabled,
.react-datepicker__day--disabled {
  @apply !text-rivaOffblack-400;
  cursor: not-allowed;
}

.react-datepicker__year-text--disabled:hover,
.react-datepicker__day--disabled:hover {
  @apply bg-white;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  @apply bg-rivaPurple-500;
  @apply text-white;
}

.react-datepicker__day--selected {
  @apply text-white;
  @apply bg-rivaPurple-500;
}

.react-datepicker__day--selected:hover {
  @apply !bg-rivaPurple-400;
}

.react-datepicker__day--outside-month {
  @apply text-rivaOffblack-400;
}

.react-datepicker__year-text {
  @apply text-sm;
  @apply font-normal;
  @apply text-rivaOffblack-900;
  line-height: 32px;
  display: block;
  width: auto !important;
  min-width: 56px;
  margin: 0 !important;
}

.react-datepicker__year-text--selected {
  @apply rounded;
  @apply bg-rivaPurple-500;
  @apply shadow-xxs;
  @apply text-white;
}

.react-datepicker__year-text--selected:hover {
  @apply bg-rivaPurple-500;
  @apply text-white;
}

.sendbird-conversation__messages {
  overflow-y: hidden !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-datetime-edit-fields-wrapper {
  @apply tabular-nums;
}

.radio-group-invalid .radio-label:hover {
  @apply bg-rivaFuchsia-100 !important;
}

::-webkit-calendar-picker-indicator {
  display: none;
}
